'use client';
import { FC } from 'react';

import { HeadingStyleWrapper } from 'components/atoms/Heading/styles';
import { IHeadingProps } from 'components/atoms/Heading/types';
import { FontWightEnum } from 'common/globalTypes/enums/typography';

const Heading: FC<IHeadingProps> = ({
  text,
  level = 1,
  fontWeight = FontWightEnum.bold,
  className = '',
  ellipsis,
  ellipsisText,
  children,
  ...restProps
}) => {
  return (
    <HeadingStyleWrapper
      level={level}
      ellipsis={ellipsis}
      className={className}
      fontWeight={fontWeight}
      data-ellipsistext={ellipsisText}
      {...restProps}
    >
      {text || children}
    </HeadingStyleWrapper>
  );
};

export default Heading;
