import BaseApiHandler from 'infrastructure/apiHandlers/baseApiHandler';
import {
  BackendResponseModelTypes,
  HeadersEnum
} from 'common/globalTypes/enums/http';
import {
  EndpointCategoriesEnum,
  ICategoriesMetaDetails,
  IGetCategoriesRequestModel
} from './types';
import langConvertByCountryCode from 'utils/helpers/langConvertByCountryCode';
import {
  ICategoriesApiResponseModel,
  ICategoriesApiResponseModelByView,
  ICategoryMetaDataApiResponseModel
} from 'common/globalTypes/interfaces/apiResponseModel';
import { DEFAULT_LANG } from 'common/constants/common';
import { LanguagesType } from 'common/globalTypes/types/common';

class CategoriesApiHandler extends BaseApiHandler {
  constructor() {
    super();
  }

  public async getCategories({
    lang = DEFAULT_LANG
  }: IGetCategoriesRequestModel): Promise<ICategoriesApiResponseModelByView[]> {
    const language = langConvertByCountryCode(lang);
    const categoriesResponse = await this.request.applicationsFetcher.get<
      ICategoriesApiResponseModel[]
    >({
      url: EndpointCategoriesEnum.prefix,
      headerOptions: {
        [HeadersEnum.xCurrentLocale]: language
      },
      next: { revalidate: 300 }
    });

    return categoriesResponse.map(({ id, name, alias }) => ({
      id,
      key: alias,
      label: name
    }));
  }

  public async getCategoryMetaDetails({
    lang = DEFAULT_LANG,
    categoryAlias
  }: ICategoriesMetaDetails) {
    const language = langConvertByCountryCode(lang);

    return await this.request.applicationsFetcher.get<ICategoryMetaDataApiResponseModel>(
      {
        url: `${EndpointCategoriesEnum.prefix}/${EndpointCategoriesEnum.byAlias}/${categoryAlias}/${EndpointCategoriesEnum.metaDetails}`,
        headerOptions: {
          [HeadersEnum.xCurrentLocale]: language
        },
        responseModel: BackendResponseModelTypes.WithData,
        next: { revalidate: 300 }
      }
    );
  }
}

const categoriesApiHandler = new CategoriesApiHandler();

export default categoriesApiHandler;
