import { useQuery } from '@tanstack/react-query';

import { ICategoriesApiResponseModelByView } from 'common/globalTypes/interfaces/apiResponseModel';
import categoriesApiHandler from 'infrastructure/apiHandlers/categories';
import useTranslations from 'hooks/common/useTranslations';
import { categoryKey } from './constants';

const useCategoriesQuery = (
  initialCategoriesFromServerSide: ICategoriesApiResponseModelByView[] = []
) => {
  const { t } = useTranslations('common');
  const categoriesQuery = useQuery({
    queryKey: [categoryKey],
    queryFn: () => categoriesApiHandler.getCategories({}),
    initialData: initialCategoriesFromServerSide,
    staleTime: Infinity
  });

  const getTitleByAlias = (categoryAlias: string) => {
    const { data: categories } = categoriesQuery;
    const categoryTitle = categories.find(
      category => category.key === categoryAlias
    );

    return categoryTitle ? categoryTitle.label : t('popularApps');
  };

  return {
    ...categoriesQuery,
    getTitleByAlias
  };
};

export default useCategoriesQuery;
