import styled from 'styled-components';

import { ParagraphProps } from './types';
import {
  FontWightEnum,
  ParagraphEnum
} from 'common/globalTypes/enums/typography';
import { typography } from 'styles/variables.module';

export const ParagraphWrapper = styled.p<ParagraphProps>`
  ${({
    size = ParagraphEnum.base,
    fontWeight = FontWightEnum.regular,
    display = 'block',
    color
  }) => `&& {
      font-size: ${typography.paragraph.size[size]};
      font-weight: ${typography.fontWeight[fontWeight]};
      color: ${color};
      line-height: 44px;
      letter-spacing: ${typography.paragraph.letterSpacing[size]}};
      display: ${display};
      margin: 0;
      
      &[data-ellipsistext='true'] {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          vertical-align: middle;
          width: 100%  
      }
  }`};
`;
