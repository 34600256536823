import { useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import applicationsApiHandler from 'infrastructure/apiHandlers/applications';
import {
  defaultCachingTime,
  pageAndLimitDefaultValue
} from 'common/constants/common';
import debounce from 'utils/helpers/debounce';
import { searchKey } from './constants';

const useSearchApplicationsInfiniteQuery = (initialSearchTerm = '') => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const { data: searchedApplications, ...infiniteQueryProps } =
    useInfiniteQuery(
      [searchKey, searchTerm],
      ({ pageParam = 1 }) => {
        return applicationsApiHandler.searchApplication(searchTerm, {
          page: pageParam,
          limit: pageAndLimitDefaultValue.limit
        });
      },
      {
        getNextPageParam: lastPage => {
          const {
            paginatorInfo: { hasMorePages, currentPage }
          } = lastPage;

          return hasMorePages ? currentPage + 1 : undefined;
        },
        staleTime: defaultCachingTime,
        enabled: Boolean(searchTerm)
      }
    );

  const onSearchTermHandler = debounce((searchTerm: string) => {
    setSearchTerm(searchTerm.toString());
  }, 500);

  const foundAppsAndTotalCount = useMemo(
    () => ({
      apps:
        searchedApplications?.pages.flatMap(application => application.items) ||
        [],
      totalCount: searchedApplications?.pages[0].paginatorInfo.total || 0
    }),
    [searchedApplications?.pages]
  );

  const foundAppsForDropDownList = useMemo(
    () =>
      foundAppsAndTotalCount.apps.map(({ name, alias }) => ({
        label: name,
        key: alias
      })) || [],
    [foundAppsAndTotalCount]
  );

  return {
    data: foundAppsAndTotalCount.apps,
    totalApplicationCount: foundAppsAndTotalCount.totalCount,
    foundAppsForDropDownList,
    onSearchTermHandler,
    searchTerm,
    ...infiniteQueryProps
  };
};

export default useSearchApplicationsInfiniteQuery;
