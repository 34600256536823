'use client';

import { FC } from 'react';
import Dropdown from 'antd/lib/dropdown';

import { IDropDownProps } from 'components/atoms/DropDown/types';

const DropDown: FC<IDropDownProps> = ({ children, ...props }) => {
  return <Dropdown {...props}>{children}</Dropdown>;
};

export default DropDown;
