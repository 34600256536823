import styled from 'styled-components';

import { borderRadiusBase } from 'styles/variables.module';

export const EmptyStateSearchResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow-y: auto;
  background: white;
  border-radius: ${borderRadiusBase};
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;
