import Typography from 'antd/lib/typography';
import styled from 'styled-components';

import { IHeadingProps } from 'components/atoms/Heading/types';
import { typography } from 'styles/variables.module';
import { FontWightEnum } from 'common/globalTypes/enums/typography';
import { LEVELS } from 'components/atoms/Heading/constants';

const { Title } = Typography;

export const HeadingStyleWrapper = styled(Title)<IHeadingProps>`
  ${({
    level = 1,
    fontWeight = FontWightEnum.bold,
    margin = 0,
    ellipsisText
  }) => {
    return `&&& {
      font-size: ${typography.heading.size[LEVELS[level]]};
      font-weight: ${typography.fontWeight[fontWeight]};  
      line-height: ${typography.heading.lineHeight[LEVELS[level]]};
      letter-spacing: ${typography.heading.letterSpacing[LEVELS[level]]}};
      margin: ${margin} !important;
      
            ${
              ellipsisText &&
              `  -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          overflow: hidden;
          vertical-align: middle;
          width: 100%  
          `
            }
    }
  `;
  }}
`;
