import Input from 'antd/lib/input';
import styled from 'styled-components';

import { SearchTypeEnum } from 'components/atoms/SearchInput/types';
import {
  borderRadiusBase,
  sizes,
  colors,
  media
} from 'styles/variables.module';

export const SearchInputStyle = styled(Input)`
  && {
    padding: ${sizes.spXm} ${sizes.sp2xm};
    border-radius: ${borderRadiusBase};
    background: ${props =>
      props['data-searchtype'] === SearchTypeEnum.main
        ? colors.gray_1
        : colors.gray_3};
    border: 0;
    max-width: 610px;
    .ant-input {
      &[data-searchtype=${SearchTypeEnum.default}] {
        background: ${colors.gray_3} !important;
      }
    }
    .ant-input-affix-wrapper {
      background-color: red;
    }

    .ant-input-prefix {
      margin-inline-end: ${sizes.sps};
    }

    .ant-input {
      background: ${colors.gray_1};
    }

    .ant-input-clear-icon {
      display: flex;
    }
    :disabled {
      pointer-events: none;
    }

    ${media.sm(` 
      padding: ${sizes.sps} ${sizes.sp2xm};
    `)}
  }
`;
