const debounce = (mainFunction: Function, delay = 500) => {
  let timer: ReturnType<typeof setTimeout>;

  return function (...args: unknown[]) {
    clearTimeout(timer);
    timer = setTimeout(() => mainFunction(args), delay);
  };
};

export default debounce;
