import { LanguagesType } from 'common/globalTypes/types/common';

export enum EndpointCategoriesEnum {
  prefix = 'application-categories',
  byAlias = 'by-alias',
  metaDetails = 'meta-details'
}

export interface IGetCategoriesRequestModel {
  lang?: LanguagesType;
}

export interface ICategoriesMetaDetails {
  lang: LanguagesType;
  categoryAlias: string;
}
