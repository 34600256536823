import { FC } from 'react';

import { ParagraphProps } from './types';
import { ParagraphWrapper } from './styles';

const Paragraph: FC<ParagraphProps> = ({
  display,
  text,
  children,
  fontWeight,
  ellipsisText = false,
  size,
  color,
  ...restProps
}) => {
  return (
    <ParagraphWrapper
      size={size}
      display={display}
      title={text}
      className={`${size}`}
      color={color}
      fontWeight={fontWeight}
      data-ellipsistext={ellipsisText}
      {...restProps}
    >
      {text || children}
    </ParagraphWrapper>
  );
};

export default Paragraph;
